import type { MainSubStructureModel } from 'egenie-utils';
import { Programme, ProgrammeComponent } from 'egenie-utils';
import React from 'react';
import { getAllVendor, getOwner } from '../../../utils';
import { BindSupplierModal } from './bindSupplierModal/bindSupplierModal';
import { BindSupplierModalStore } from './bindSupplierModal/bindSupplierModalStore';
import { ENUM_PREPARATION_TYPE } from './constants';
import { CreatePurchaseModal } from './createPurchaseModal/createPurchaseModal';
import { CreatePurchaseModalStore } from './createPurchaseModal/createPurchaseModalStore';
import { mainSubStructureModel } from './mainSubStructureModel';

export class Store {
  constructor() {
    getOwner()
      .then((ownerIds) => this.programme.filterItems.addDict({ ownerIds }));
    getAllVendor()
      .then((vendorShopIds) => {
        this.programme.filterItems.addDict({ vendorShopIds });
        this.createPurchaseModalStore.normalProgramme.filterItems.addDict({ vendorShopIds });
      });
  }

  public createPurchaseModalStore = new CreatePurchaseModalStore();

  public bindSupplierModalStore = new BindSupplierModalStore();

  public mainSubStructureModel: MainSubStructureModel = mainSubStructureModel(this);

  public cacheParams: {[key: string]: string | string[]; } = {};

  public programme: Programme = new Programme({
    gridModel: this.mainSubStructureModel,
    filterItems: [
      {
        type: 'select',
        mode: 'multiple',
        field: 'ownerIds',
        label: '货主',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'vendorShopIds',
        label: '供应商',
      },
      {
        type: 'input',
        field: 'skuNo',
        label: 'SKU编码',
      },
      {
        type: 'input',
        field: 'productNo',
        label: '商品编码',
      },
      {
        type: 'select',
        field: 'preparationType',
        label: '采购方式',
        mode: 'multiple',
        data: Object.values(ENUM_PREPARATION_TYPE),
      },
    ],
    moduleName: 'egenieCloudWmsPurchaseOrderPurchaseStockIndex',
  });
}

const store = new Store();

export default function() {
  return (
    <>
      <ProgrammeComponent store={store.programme}/>
      <CreatePurchaseModal createPurchaseModalStore={store.createPurchaseModalStore}/>
      <BindSupplierModal bindSupplierModalStore={store.bindSupplierModalStore}/>
    </>
  );
}
