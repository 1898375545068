import { Button, message, Row, Space, Tooltip } from 'antd';
import { destroyAllModal, renderModal } from 'egenie-common';
import type { PaginationData } from 'egenie-utils';
import { MainSubStructureModel, request } from 'egenie-utils';
import { nanoid } from 'nanoid';
import React from 'react';
import { detailGrid } from './detailGrid';
import { SetPreparationModal } from './setPreparationModal';
import type { MainItem } from './types';
import type { Store } from './index';

export function mainSubStructureModel(context: Store) {
  return new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          width: 200,
          frozen: true,
          formatter: ({ row }: { row: MainItem; }) => {
            return (
              <Space>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    context.createPurchaseModalStore.handleOpen(row, context.cacheParams);
                  }}
                  size="small"
                  type="link"
                >
                  生成采购单
                </Button>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    context.bindSupplierModalStore.handleOpen(row, context.cacheParams);
                  }}
                  size="small"
                  type="link"
                >
                  绑定备货供应商
                </Button>
              </Space>
            );
          },
        },
        {
          key: 'ownerName',
          name: '货主',
          width: 300,
        },
        {
          key: 'ownerNumber',
          name: '实物库存',
          width: 80,
        },
        {
          key: 'onlineNumber',
          name: '在途库存',
          width: 80,
        },
        {
          key: 'outOfNumber',
          name: '缺货库存',
          width: 80,
        },
        {
          key: 'intelligenceNumber',
          name: '备货预测',
          width: 80,
        },
        {
          key: 'planNumber',
          name: (
            <Row align="middle">
              计划采购&nbsp;
              <Tooltip title="计划采购= 缺货库存 + 备货预测 - 在途库存">
                <i className="icon-note_zs"/>
              </Tooltip>
            </Row>
          ),
          width: 80,
        },
        {
          key: 'preparationType',
          name: '采购方式',
          width: 200,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: '_id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 200,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/purchaseOrder/purchaseStock/index/main',
    },
    pageId: '60113',
    buttons: [
      {
        permissionId: '4073',
        text: '设置采购备货方式',
        icon: 'icon-aqsz',
        handleClick: () => {
          const selectRows: MainItem[] = context.mainSubStructureModel.gridModel.selectRows;

          if (selectRows.length !== 1) {
            const error = '请选择一条数据';
            message.warning({
              key: error,
              content: error,
            });
            return;
          }

          if (selectRows[0].ownerId == null) {
            const error = '货主为空';
            message.warning({
              key: error,
              content: error,
            });
            return;
          }

          renderModal(
            <SetPreparationModal
              callback={(params) => request({
                url: '/api/cloud/pms/sales/intelligence/owner/purchase/preparation/type/add',
                method: 'POST',
                data: {
                  ownerId: selectRows[0].ownerId,
                  preparationTypes: (params.preparationTypes || []).join(','),
                },
              })
                .then(() => {
                  message.success('设置成功');
                  destroyAllModal();
                  context.mainSubStructureModel.onQuery();
                })}
              onCancel={destroyAllModal}
            />
          );
        },
      },
    ],

    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/pms/sales/intelligence/owner/list/page',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
        })
          .then((info) => {
            context.cacheParams = context.programme.filterItems.params;
            return {
              status: 'Successful',
              data: {
                ...info.data,
                list: (info.data?.list || []).map((item) => ({
                  ...item,
                  _id: nanoid(),
                })),
              },
            };
          });
      },
    },
    subTables: {
      activeTab: 'detail',
      tabsFlag: {
        inited: { detail: true },
        searched: {},
      },
      list: [detailGrid(context)],
    },
  });
}
