import type { PaginationData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { nanoid } from 'nanoid';
import React from 'react';
import type { SubItem } from './types';
import type { Store } from './index';

export function detailGrid(context: Store) {
  return {
    tab: {
      name: '供应商明细',
      value: 'detail',
    },
    grid: {
      columns: [
        {
          key: 'vendorNo',
          name: '供应商',
          width: 200,
        },
        {
          key: 'ownerNumber',
          name: '实物库存',
          width: 80,
        },
        {
          key: 'onlineNumber',
          name: '在途库存',
          width: 80,
        },
        {
          key: 'outOfNumber',
          name: '缺货量',
          width: 80,
        },
        {
          key: 'intelligenceNumber',
          name: '备货预测',
          width: 80,
        },
        {
          key: 'planNumber',
          name: '计划采购',
          width: 80,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: '_id',
      sortByLocal: false,
      showCheckBox: false,
      showReset: false,
      showSelectedTotal: false,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/purchaseOrder/purchaseStock/index/detail',
    },
    api: {
      onQuery: ({
        data,
        cursorRow,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
        } = data;
        return request<PaginationData<SubItem[]>>({
          url: '/api/cloud/pms/sales/intelligence/vendor/list/page',
          method: 'POST',
          data: {
            sidx,
            sord,
            page,
            pageSize,
            ownerId: cursorRow.ownerId,
            warehouseId: cursorRow.warehouseId,
            skuNo: cursorRow.skuNo,
            productNo: cursorRow.productNo,
            vendorShopIds: context.programme.filterItems.params.vendorShopIds,
          },
        })
          .then((info) => ({
            status: 'Successful',
            data: {
              ...info.data,
              list: (info.data?.list || []).map((item) => ({
                ...item,
                _id: nanoid(),
              })),
            },
          }));
      },
    },
  };
}
