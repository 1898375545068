import type { DictData } from 'egenie-common';
import { objToDict } from 'egenie-common';
import type { BaseData, ValueAndLabelData } from 'egenie-utils';
import { request } from 'egenie-utils';
import _ from 'lodash';

export interface VendorItem {
  vendorName: string;
  vendorShopId: string;
  vendorTenantId: string;
  stockToWarehouse?: boolean;
  value: string;
  label: string;
}

export function getShopList(): Promise<ValueAndLabelData> {
  return request<Array<{ shopName: string; shopId: string; }>>({ url: '/api/cloud/baseinfo/rest/shop/list' })
    .then((info) => {
      if (Array.isArray(info)) {
        return info.map((item) => ({
          value: `${item.shopId}`,
          label: item.shopName,
        }));
      } else {
        return [];
      }
    });
}

export function getCourierEnabled(): Promise<ValueAndLabelData> {
  return request<BaseData<Array<{ courierName: string; courierId: string; }>>>({
    method: 'POST',
    url: '/api/cloud/wms/rest/courier/queryEnable',
  })
    .then((info) => {
      if (Array.isArray(info.data)) {
        return info.data.map((item) => ({
          value: `${item.courierId}`,
          label: item.courierName,
        }));
      } else {
        return [];
      }
    });
}

export function getWarehouse(): Promise<ValueAndLabelData> {
  return request<Array<{ warehouse_id: string; warehouse_name: string; }>>({ url: '/api/cloud/baseinfo/rest/warehouse/listAll' })
    .then((info) => {
      if (Array.isArray(info)) {
        return info.map((item) => ({
          value: `${item.warehouse_id}`,
          label: item.warehouse_name,
        }));
      } else {
        return [];
      }
    });
}

export function getOtherWarehouse(): Promise<ValueAndLabelData> {
  return request<BaseData<DictData>>({ url: '/api/cloud/baseinfo/rest/warehouse/other' })
    .then((info) => objToDict(info.data));
}

export function getWarehouseArea(data?: Partial<{[key: string]: string | number; }>): Promise<ValueAndLabelData> {
  return request<BaseData<DictData>>({
    method: 'POST',
    url: '/api/cloud/baseinfo/rest/warehouse/area/queryIdAndName',
    data,
  })
    .then((info) => objToDict(info.data));
}

export function getWarehouseBin(data?: Partial<{[key: string]: string | number; }>): Promise<ValueAndLabelData> {
  return request<BaseData<DictData>>({
    method: 'POST',
    url: '/api/cloud/baseinfo/rest/warehouse/bin/queryIdAndName',
    data,
  })
    .then((info) => objToDict(info.data));
}

export function getOwner(params = {}): Promise<ValueAndLabelData> {
  return request<Array<{ enable: boolean; ownerId: string; ownerName: string; }>>({
    url: '/api/cloud/baseinfo/rest/owner/queryOwner',
    params,
  })
    .then((data) => {
      if (Array.isArray(data)) {
        return data.filter((item) => item.enable)
          .map((item) => ({
            ...item,
            value: String(item.ownerId),
            label: item.ownerName,
          }));
      } else {
        return [];
      }
    });
}

export function getConsignor(params = {}, headers = {}): Promise<ValueAndLabelData> {
  return request<BaseData<Array<{ ownerId?: string; ownerName?: string; }>>>({
    url: '/api/cloud/baseinfo/rest/owner/query/type',
    method: 'post',
    data: { ownerType: 3 },
    headers,
  })
    .then((info) => (info.data || []).map((item) => ({
      value: `${item.ownerId}`,
      label: item.ownerName,
      ...item,
    })));
}

export function getAllVendor(): Promise<VendorItem[]> {
  return request<BaseData<VendorItem[]>>({
    url: '/api/cloud/pms/vendor/query/all/vendor',
    method: 'post',
  })
    .then((info) => (info.data || []).map((item) => ({
      ...item,
      value: _.toString(item.vendorShopId),
      label: item.vendorName,
    })));
}

export function getVendorByOwner(ownerId: string | number): Promise<ValueAndLabelData> {
  return request<BaseData<Array<{ vendorId: string; vendorName: string; }>>>({ url: `/api/cloud/baseinfo/rest/value/added/${ownerId}/vendor/list` })
    .then((info) => (info.data || []).map((item) => ({
      ...item,
      value: _.toString(item.vendorId),
      label: item.vendorName,
    })));
}

export function getAddress(parentId: number | string, scope: number | string): Promise<ValueAndLabelData> {
  return request<BaseData<Array<{ locationId: number; locationName: string; }>>>({
    method: 'POST',
    url: '/api/cloud/wms/rest/recipientInfo/cascade',
    data: {
      scope,
      parentId,
    },
  })
    .then((info) => {
      if (Array.isArray(info.data)) {
        return info.data.map((item) => ({
          value: `${item.locationId}`,
          label: item.locationName,
        }));
      } else {
        return [];
      }
    });
}

export function isShareOwner(ownerId?: string | number): boolean {
  return _.toString(ownerId) && _.toString(ownerId) === _.toString(window?.top?.user?.tenantId);
}

export function isChooseStyleOwner(ownerId?: string | number): boolean {
  return _.toString(ownerId) && _.toString(ownerId) === '-3';
}

export function getTagList(): Promise<ValueAndLabelData> {
  return request<BaseData<Array<{ id: string; name: string; }>>>({ url: '/api/cloud/baseinfo/rest/item/tag/list/page' })
    .then((info) => {
      if (Array.isArray(info.data)) {
        return info.data.map((item) => ({
          value: `${item.id}`,
          label: item.name,
        }));
      } else {
        return [];
      }
    });
}

export function getWarehouseBinType(): Promise<ValueAndLabelData> {
  return request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/warehouse/shelf/type' })
    .then((info) => objToDict(info.data));
}

