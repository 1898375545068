import { Typography } from 'antd';
import classnames from 'classnames';
import React from 'react';
import styles from './modalHeaderContent.less';

interface ModalHeaderContentProps {
  data: Array<{
    value: string;
    label: string;
    icon: string;
  }>;
  className?: string;
  style?: React.CSSProperties;
}

export const ModalHeaderContent: React.FC<ModalHeaderContentProps> = ({
  data,
  className = '',
  style = {},
}) => {
  return (
    <div
      className={classnames(styles.modalHeaderContent, className)}
      style={style}
    >
      {
        data.map((item) => (
          <section
            key={item.label}
            style={{ width: `${100 / data.length}%` }}
          >
            <img
              alt=""
              height={32}
              src={item.icon}
              width={29}
            />
            <div className={styles.content}>
              <Typography.Title
                ellipsis
                level={3}
                title={item.value}
              >
                {item.value}
              </Typography.Title>
              <span>
                {item.label}
              </span>
            </div>
          </section>
        ))
      }
    </div>
  );
};
