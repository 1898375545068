export const ENUM_PREPARATION_TYPE = {
  predict: {
    value: '2',
    label: '智能预测',
    sourceTypeLabel: '仅显示智能预测数据',
  },
  outOfStock: {
    label: '缺货采购',
    value: '1',
    sourceTypeLabel: '仅显示缺货采购数据',
  },
};

export const ENUM_SUPPLIER_BIND_STATUS = {
  all: {
    value: '',
    label: '全部',
  },
  yes: {
    value: '1',
    label: '已绑定',
  },
  no: {
    value: '0',
    label: '未绑定',
  },
};
