import { Button, message, Modal, Tag } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import { MainSubStructureModel, NormalProgramme } from 'egenie-utils';
import { action, observable } from 'mobx';
import { nanoid } from 'nanoid';
import React from 'react';
import { ENUM_SUPPLIER_BIND_STATUS } from '../constants';
import type { SupplierItem, MainItem } from '../types';

interface Params {
  skuNo?: string;
  productNo?: string;
  vendorShopIds?: string;
}

class ConditionStore {
  constructor(private readonly parent: BindSupplierModalStore) {
  }

  @observable public bindStatus: string = ENUM_SUPPLIER_BIND_STATUS.all.value;

  @action public handleBindStatusChange = (event): void => {
    this.bindStatus = event.target.value;
    this.parent.mainSubStructureModel.onQuery();
  };

  public toParams = (): {[key: string]: string; } => {
    return { bindStatus: this.bindStatus };
  };
}

export class BindSupplierModalStore {
  constructor() {
    this.conditionStore = new ConditionStore(this);
    this.normalProgramme.filterItems.connect(this.conditionStore);
  }

  public conditionStore: ConditionStore;

  @observable public supplierCount = 0;

  @observable.ref public mainItem: MainItem = {};

  @observable.ref public params: Params = {};

  @observable public visible = false;

  @action public handleOpen = (mainItem: MainItem, params: Params = {}) => {
    this.mainSubStructureModel.gridModel.resetAll();
    this.normalProgramme.filterItems.updateFilterItem([
      {
        field: 'vendorShopIds',
        type: 'select',
        value: (params.vendorShopIds || '').split(',')
          .filter(Boolean),
        disabled: Boolean(params.vendorShopIds),
      },
    ]);

    this.mainItem = { ...mainItem };
    this.params = params;
    this.visible = true;
    this.mainSubStructureModel.onQuery();
    this.getBindSupplierCount();
    this.getSupplier();
  };

  @action private getSupplier = () => {
    request<BaseData<Array<{ vendorShopId: string; vendorName: string; }>>>({
      url: '/api/cloud/pms/sales/intelligence/vendor/list',
      method: 'post',
      data: {
        ownerId: this.mainItem.ownerId,
        skuNo: this.params.skuNo,
        productNo: this.params.productNo,
        vendorShopIds: this.params.vendorShopIds,
      },
    })
      .then((info) => this.normalProgramme.filterItems.addDict({
        vendorShopIds: (info.data || []).map((item) => ({
          value: item.vendorShopId,
          label: item.vendorName,
        })),
      }));
  };

  @action public getBindSupplierCount = () => {
    request<BaseData<number>>({
      url: '/api/cloud/pms/sales/intelligence/bind/count',
      method: 'post',
      data: {
        ownerId: this.mainItem.ownerId,
        skuNo: this.params.skuNo,
        productNo: this.params.productNo,
        vendorShopIds: this.normalProgramme.filterItems.params.vendorShopIds,
      },
    })
      .then((info) => this.supplierCount = info.data || 0);
  };

  @action public handleBindStatus = (data: { bindStatus: 1 | 0; vendorShopIdList: string[]; }) => {
    if (data.vendorShopIdList.length <= 0) {
      const error = '请选择数据';
      message.warning({
        key: error,
        content: error,
      });
      return;
    }

    Modal.confirm({
      content: data.bindStatus ? '确定绑定吗?' : '确定解绑吗?',
      onOk: async() => {
        await request({
          url: '/api/cloud/pms/sales/intelligence/bind/vendor/edit',
          method: 'post',
          data: {
            ...data,
            ownerId: this.mainItem.ownerId,
          },
        });

        this.mainSubStructureModel.onQuery();
        this.getBindSupplierCount();
        message.success('操作成功');
      },
    });
  };

  @action public handleClose = () => {
    this.mainSubStructureModel.gridModel.clearToOriginal();
    this.mainSubStructureModel.gridModel.resetAll();
    this.normalProgramme.filterItems.updateFilterItem([
      {
        field: 'vendorShopIds',
        type: 'select',
        value: [],
        disabled: false,
        data: [],
      },
    ]);
    this.conditionStore.bindStatus = ENUM_SUPPLIER_BIND_STATUS.all.value;
    this.visible = false;
    this.mainItem = {};
    this.params = {};
    this.supplierCount = 0;
  };

  @observable public isCreate = false;

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          width: 100,
          frozen: true,
          formatter: ({ row }: { row: SupplierItem; }) => {
            return row.bindStatus ? (
              <Button
                danger
                onClick={(event) => {
                  event.stopPropagation();
                  this.handleBindStatus({
                    bindStatus: 0,
                    vendorShopIdList: [row.vendorShopId],
                  });
                }}
                size="small"
                type="link"
              >
                取消绑定
              </Button>
            ) : (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  this.handleBindStatus({
                    bindStatus: 1,
                    vendorShopIdList: [row.vendorShopId],
                  });
                }}
                size="small"
                type="link"
              >
                绑定备货
              </Button>
            );
          },
        },
        {
          key: 'bindStatus',
          name: '绑定备货状态',
          width: 100,
          formatter: ({ row }) => {
            return (
              row.bindStatus ? (
                <Tag color="#02C190">
                  已绑定备货
                </Tag>
              ) : (
                <Tag color="#1978ff">
                  未绑定备货
                </Tag>
              )
            );
          },
        },
        {
          key: 'cloudPmsVendorNo',
          name: '供应商编号',
          width: 200,
        },
        {
          key: 'vendorName',
          name: '供应商名称',
          width: 200,
        },
        {
          key: 'mobile',
          name: '手机号码',
          width: 120,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: '_id',
      sortByLocal: false,
      showCheckBox: true,
      showReset: true,
      showEmpty: false,
      showPagination: false,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/purchaseOrder/purchaseStock/index/bindSupplierModal',
    },
    api: {
      onQuery: () => {
        return request<BaseData<SupplierItem[]>>({
          url: '/api/cloud/pms/sales/intelligence/bind/vendor/list/page',
          method: 'POST',
          data: {
            ...this.params,
            ...this.normalProgramme.filterItems.params,
            ownerId: this.mainItem.ownerId,
            warehouseId: this.mainItem.warehouseId,
          },
        })
          .then((info) => ({
            ...info,
            data: {
              ...info.data,
              list: (info.data || []).map((item) => ({
                ...item,
                _id: nanoid(),
              })),
            },
          }));
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'select',
        field: 'vendorShopIds',
        mode: 'multiple',
        label: '供应商',
        placeholder: '请选择供应商',
        labelWidth: 0,
        onChangeCallback: () => this.mainSubStructureModel.onQuery(),
      },
    ],
    count: 1,
    showButton: false,
    handleSearch: () => this.mainSubStructureModel.onQuery(),
  });
}

