import { Button, Col, Modal, Radio, Row } from 'antd';
import { NormalProgrammeComponent, MainSubStructure } from 'egenie-utils';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ENUM_SUPPLIER_BIND_STATUS } from '../constants';
import { ModalHeaderContent } from '../modalHeaderContent/modalHeaderContent';
import styles from './bindSupplierModal.less';
import type { BindSupplierModalStore } from './bindSupplierModalStore';

@observer
export class BindSupplierModal extends Component<{ bindSupplierModalStore: BindSupplierModalStore; }> {
  render() {
    const {
      bindSupplierModalStore: {
        handleClose,
        visible,
        mainSubStructureModel,
        isCreate,
      },
      bindSupplierModalStore,
    } = this.props;

    return (
      <Modal
        centered
        destroyOnClose
        footer={null}
        maskClosable={false}
        okButtonProps={{
          loading: isCreate,
          style: { display: 'none' },
        }}
        onCancel={handleClose}
        title="绑定备货供应商"
        visible={visible}
        width={1100}
      >
        <HeaderContent bindSupplierModalStore={bindSupplierModalStore}/>
        <ConditionContent bindSupplierModalStore={bindSupplierModalStore}/>
        <div style={{ height: 540 }}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
      </Modal>
    );
  }
}

@observer
class ConditionContent extends Component<{ bindSupplierModalStore: BindSupplierModalStore; }> {
  render() {
    const {
      bindSupplierModalStore: {
        normalProgramme,
        handleBindStatus,
        mainSubStructureModel,
        conditionStore: {
          bindStatus,
          handleBindStatusChange,
        },
      },
    } = this.props;

    return (
      <Row
        align="middle"
        className={styles.createPurchaseCondition}
        gutter={[
          8,
          0,
        ]}
      >
        <Col span={3}>
          <Button
            onClick={(event) => {
              handleBindStatus({
                bindStatus: 1,
                vendorShopIdList: mainSubStructureModel.gridModel.selectRows.map((item) => item.vendorShopId),
              });
            }}
            style={{ width: '100%' }}
          >
            绑定备货
          </Button>
        </Col>
        <Col span={3}>
          <Button
            onClick={(event) => {
              handleBindStatus({
                bindStatus: 0,
                vendorShopIdList: mainSubStructureModel.gridModel.selectRows.map((item) => item.vendorShopId),
              });
            }}
            style={{ width: '100%' }}
          >
            取消绑定
          </Button>
        </Col>
        <Col span={6}>
          <NormalProgrammeComponent store={normalProgramme}/>
        </Col>
        <Col
          offset={4}
          span={8}
        >
          <Row justify="end">
            <Radio.Group
              onChange={handleBindStatusChange}
              optionType="button"
              options={Object.values(ENUM_SUPPLIER_BIND_STATUS)}
              value={bindStatus}
            />
          </Row>
        </Col>
      </Row>
    );
  }
}

@observer
class HeaderContent extends Component<{ bindSupplierModalStore: BindSupplierModalStore; }> {
  render() {
    const {
      bindSupplierModalStore: {
        mainItem,
        supplierCount,
      },
    } = this.props;

    const data = [
      {
        value: mainItem?.ownerName || '',
        label: '货主',
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        icon: require('../img/owner.png'),
      },
      {
        value: _.toString(supplierCount),
        label: '绑定备货供应商(个)',
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        icon: require('../img/supplier.png'),
      },
    ];

    return (
      <ModalHeaderContent data={data}/>
    );
  }
}

