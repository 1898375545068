import { InputNumber, message, Row, Tooltip, Typography } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { formatNumber, request } from 'egenie-common';
import { MainSubStructureModel, NormalProgramme } from 'egenie-utils';
import { action, observable } from 'mobx';
import { Observer } from 'mobx-react';
import { nanoid } from 'nanoid';
import React from 'react';
import type { CreatePurchaseItem, MainItem } from '../types';
import styles from './createPurchaseModal.less';

interface Params {
  skuNo?: string;
  vendorShopIds?: string;
  preparationType?: string;
}

class ConditionStore {
  constructor(private readonly parent: CreatePurchaseModalStore) {
  }

  @observable public sourceType: string[] = [];

  @action public handleSourceTypeChange = (sourceType: string[]): void => {
    this.sourceType = sourceType;
    this.parent.mainSubStructureModel.onQuery();
  };

  public toParams = (): {[key: string]: string; } => {
    if (this.sourceType && this.sourceType.length) {
      return { sourceType: this.sourceType.join(',') };
    } else {
      return {};
    }
  };
}

export class CreatePurchaseModalStore {
  constructor() {
    this.conditionStore = new ConditionStore(this);
    this.normalProgramme.filterItems.connect(this.conditionStore);
  }

  @observable public params: Params = {};

  public conditionStore: ConditionStore;

  @observable public supplierCount = 0;

  @observable.ref public mainItem: MainItem = {};

  @observable public visible = false;

  @action public handleOpen = (mainItem: MainItem, params: Params = {}) => {
    this.mainSubStructureModel.gridModel.resetAll();
    this.normalProgramme.filterItems.updateFilterItem([
      {
        field: 'skuNo',
        type: 'input',
        value: params?.skuNo || '',
        disabled: Boolean(params.skuNo),
      },
      {
        field: 'vendorShopIds',
        type: 'select',
        value: (params.vendorShopIds || '').split(',')
          .filter(Boolean),
        disabled: Boolean(params.vendorShopIds),
      },
    ]);
    this.conditionStore.sourceType = params.preparationType ? params.preparationType.split(',')
      .filter(Boolean) : [];
    this.mainItem = { ...mainItem };
    this.params = { ...params };
    this.visible = true;
    this.mainSubStructureModel.onQuery();
    this.getBindSupplierCount();
  };

  @action public handleClose = () => {
    this.mainSubStructureModel.gridModel.clearToOriginal();
    this.mainSubStructureModel.gridModel.resetAll();
    this.normalProgramme.filterItems.updateFilterItem([
      {
        field: 'skuNo',
        type: 'input',
        value: '',
        disabled: false,
      },
      {
        field: 'vendorShopIds',
        type: 'select',
        value: [],
        disabled: false,
      },
    ]);
    this.conditionStore.sourceType = [];
    this.visible = false;
    this.params = {};
    this.mainItem = {};
    this.supplierCount = 0;
  };

  @action public getBindSupplierCount = () => {
    request<BaseData<number>>({
      url: '/api/cloud/pms/sales/intelligence/bind/count',
      method: 'post',
      data: {
        ownerId: this.mainItem.ownerId,
        skuNo: this.params.skuNo,
        vendorShopIds: this.normalProgramme.filterItems.params.vendorShopIds,
      },
    })
      .then((info) => this.supplierCount = info.data || 0);
  };

  @observable public isCreate = false;

  @action public handleCreate = () => {
    const selectedRows = this.mainSubStructureModel.gridModel.selectRows;

    this.isCreate = true;
    request({
      url: '/api/cloud/pms/sales/intelligence/create',
      method: 'post',
      data: {
        ...this.mainItem,
        ...this.normalProgramme.filterItems.params,
        detailVOS: selectedRows,
      },
    })
      .then(() => {
        this.handleClose();
        message.success('创建成功');
      })
      .finally(() => this.isCreate = false);
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'cloudPmsVendorNo',
          name: '供应商编码',
          width: 200,
        },
        {
          key: 'pic',
          name: '商品',
          width: 250,
          formatter: ({ row }: { row: CreatePurchaseItem; }) => {
            return (
              <div className={styles.createPurchaseGoodsInfo}>
                <div className={styles.left}>
                  <img
                    alt=""
                    src={row.pic}
                  />
                </div>
                <div className={styles.right}>
                  <section>
                    颜色/尺码:&nbsp;
                    <Typography.Text ellipsis>
                      {[
                        row.color,
                        row.size,
                      ].filter(Boolean)
                        .join('/')}
                    </Typography.Text>
                  </section>
                  <section>
                    商品编码:&nbsp;
                    <Typography.Text
                      copyable
                      ellipsis
                      title={row.productNo || ''}
                    >
                      {row.productNo || ''}
                    </Typography.Text>
                  </section>
                  <section>
                    SKU编码:&nbsp;
                    <Typography.Text
                      copyable
                      ellipsis
                      title={row.skuNo || ''}
                    >
                      {row.skuNo || ''}
                    </Typography.Text>
                  </section>
                </div>
              </div>
            );
          },
        },
        {
          key: 'planNumber',
          name: (
            <Row align="middle">
              计划采购&nbsp;
              <Tooltip title="计划采购= 缺货库存 + 备货预测 - 在途库存">
                <i className="icon-note_zs"/>
              </Tooltip>
            </Row>
          ),
          width: 100,
        },
        {
          key: 'intelligenceNumber',
          name: '备货预测',
          width: 120,
          formatter: ({ rowIdx, row }) => {
            return (
              <Observer>
                {
                  () => (
                    <InputNumber
                      max={999999}
                      min={0}
                      onChange={(value) => {
                        this.mainSubStructureModel.gridModel.rows[rowIdx].intelligenceNumber = formatNumber(value);
                        this.mainSubStructureModel.gridModel.rows[rowIdx].planNumber = formatNumber(value) + formatNumber(row.outOfNumber) - formatNumber(row.onlineNumber);
                      }}
                      onClick={(event) => event.stopPropagation()}
                      size="middle"
                      value={this.mainSubStructureModel.gridModel.rows[rowIdx].intelligenceNumber}
                    />
                  )
                }
              </Observer>
            );
          },
        },
        {
          key: 'outOfNumber',
          name: '缺货库存',
          width: 80,
        },
        {
          key: 'ownerNumber',
          name: '实物库存',
          width: 80,
        },
        {
          key: 'onlineNumber',
          name: '在途库存',
          width: 80,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      rowHeight: 72,
      primaryKeyField: '_id',
      sortByLocal: false,
      showCheckBox: true,
      showReset: true,
      showEmpty: false,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/purchaseOrder/purchaseStock/index/createPurchaseModal',
    },
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;

        return request<PaginationData<CreatePurchaseItem[]>>({
          url: '/api/cloud/pms/sales/intelligence/list/page/sku',
          method: 'POST',
          data: {
            ...rest,
            ...this.normalProgramme.filterItems.params,
            ownerId: this.mainItem.ownerId,
          },
        })
          .then((info) => ({
            ...info,
            data: {
              ...info.data,
              list: (info.data.list || []).map((item) => ({
                ...item,
                _id: nanoid(),
              })),
            },
          }));
      },
    },
    hiddenSubTable: true,
  });

  public normalProgramme: NormalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'skuNo',
        label: 'SKU编码',
        labelWidth: 0,
        placeholder: '请输入SKU编码',
        onPressEnter: () => this.mainSubStructureModel.onQuery(),
      },
      {
        type: 'select',
        field: 'vendorShopIds',
        mode: 'multiple',
        label: '供应商',
        labelWidth: 0,
        placeholder: '选择供应商',
        onChangeCallback: () => this.mainSubStructureModel.onQuery(),
      },
    ],
    count: 2,
    showButton: false,
    handleSearch: () => this.mainSubStructureModel.onQuery(),
  });
}

