import { Form, Modal, Checkbox } from 'antd';
import React from 'react';
import { ENUM_PREPARATION_TYPE } from './constants';

interface SetPreparationModalProps {
  callback: (params: { preparationTypes: string []; }) => Promise<unknown>;
  onCancel: () => void;
}

export function SetPreparationModal({
  callback,
  onCancel,
}: SetPreparationModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState({
    loading: false,
    params: null,
  });
  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title="设置采购备货方式"
      visible
      width={400}
    >
      <Form
        form={form}
        initialValues={{ preparationTypes: []}}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="采购备货方式"
          name="preparationTypes"
          rules={[
            {
              required: false,
              message: '请选择采购备货方式',
            },
          ]}
        >
          <Checkbox.Group options={Object.values(ENUM_PREPARATION_TYPE)}/>
        </Form.Item>
      </Form>
    </Modal>
  );
}
