import { Checkbox, Col, Modal, Row } from 'antd';
import { NormalProgrammeComponent, MainSubStructure } from 'egenie-utils';
import _ from 'lodash';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { ENUM_PREPARATION_TYPE } from '../constants';
import { ModalHeaderContent } from '../modalHeaderContent/modalHeaderContent';
import styles from './createPurchaseModal.less';
import type { CreatePurchaseModalStore } from './createPurchaseModalStore';

@observer
export class CreatePurchaseModal extends Component<{ createPurchaseModalStore: CreatePurchaseModalStore; }> {
  render() {
    const {
      createPurchaseModalStore: {
        handleClose,
        visible,
        mainSubStructureModel,
        handleCreate,
        isCreate,
      },
      createPurchaseModalStore,
    } = this.props;

    return (
      <Modal
        centered
        destroyOnClose
        maskClosable={false}
        okButtonProps={{ loading: isCreate }}
        okText="生成采购单"
        onCancel={handleClose}
        onOk={handleCreate}
        title="生成采购单"
        visible={visible}
        width={1100}
      >
        <HeaderContent createPurchaseModalStore={createPurchaseModalStore}/>
        <ConditionContent createPurchaseModalStore={createPurchaseModalStore}/>
        <div style={{ height: 540 }}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
      </Modal>
    );
  }
}

@observer
class ConditionContent extends Component<{ createPurchaseModalStore: CreatePurchaseModalStore; }> {
  render() {
    const {
      createPurchaseModalStore: {
        normalProgramme,
        params,
        conditionStore: {
          sourceType,
          handleSourceTypeChange,
        },
      },
    } = this.props;

    return (
      <Row
        align="middle"
        className={styles.createPurchaseCondition}
      >
        <Col span={12}>
          <NormalProgrammeComponent store={normalProgramme}/>
        </Col>
        <Col
          offset={4}
          span={8}
        >
          <Row justify="end">
            <Checkbox.Group
              disabled={Boolean(params.preparationType)}
              onChange={handleSourceTypeChange}
              options={Object.values(ENUM_PREPARATION_TYPE)
                .map((item) => ({
                  value: item.value,
                  label: item.sourceTypeLabel,
                }))}
              value={sourceType}
            />
          </Row>
        </Col>
      </Row>
    );
  }
}

@observer
class HeaderContent extends Component<{ createPurchaseModalStore: CreatePurchaseModalStore; }> {
  render() {
    const {
      createPurchaseModalStore: {
        mainItem,
        supplierCount,
      },
    } = this.props;

    const data = [
      {
        value: mainItem?.ownerName || '',
        label: '货主',
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        icon: require('../img/owner.png'),
      },
      {
        value: _.toString(supplierCount),
        label: '绑定备货供应商(个)',
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        icon: require('../img/supplier.png'),
      },
      {
        value: _.toString(mainItem?.planNumber) || '0',
        label: '计划采购',
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        icon: require('../img/plan.png'),

      },
      {
        value: _.toString(mainItem?.intelligenceNumber) || '0',
        label: '备货预测',
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        icon: require('../img/intelligence.png'),
      },
      {
        value: _.toString(mainItem?.outOfNumber) || '0',
        label: '缺货库存',
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        icon: require('../img/outOf.png'),
      },
    ];

    return (
      <ModalHeaderContent data={data}/>
    );
  }
}

